<template>
  <div class="licenses">
    <MobileTable
      v-model:selection="store.state.personnel.selectedLicenseTypeList"
      label="Listado de tipos de licencia"
      :columns="license.columns"
      :data="store.state.personnel.licenseTypeList"
    />
    <DesktopTable
      v-model:selection="store.state.personnel.selectedLicenseTypeList"
      label="Listado de tipos de licencia"
      :columns="license.columns"
      :data="store.state.personnel.licenseTypeList"
    />
    <Actions :items="license.actions" />

    <BasicModal v-model:visible="license.showDialog">
      <template v-slot:dialog>
        <BasicTitle title="Nuevo Tipo de Licencia" />
        <BasicSubtitle
          subtitle="Los campos señalados con (*) son obligatorios."
        />

        <div class="container-form">
          <div class="checkbox-container">
            <BasicLabel label="Nombre *" />
            <BorderInput
              v-model="license.data.name"
              label="Nombre"
              @keyup.enter="onSave"
            />
            <FormError :show="license.rules.name" message="Ingrese un nombre" />
          </div>
          <PrimaryButton label="Guardar" :click="onSave" />
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: {
    MobileTable,
    DesktopTable,
    Actions,
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    FormError,
    PrimaryButton,
    BasicModal,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      license.showDialog = true;
    };
    const onDelete = async () => {
      if (!store.state.personnel.selectedLicenseTypeList.length) {
        alert("Debe seleccionar un registro!");
      } else {
        store.commit("setLoading", true);
        store.commit("setSelectedLicenseTypeList", []);
        const request = {
          types: store.state.personnel.selectedLicenseTypeList.map(
            (item) => item.id
          ),
        };
        await store.dispatch(
          actions.personnelActions.deleteLicenseType,
          request
        );
        await store.dispatch(
          actions.personnelActions.findAllLicenseType,
          store.state.general.selectedBuilding
        );
        store.commit("setLoading", false);
      }
    };

    const license = reactive({
      showDialog: false,
      data: {
        id: "",
        name: "",
      },
      rules: {
        name: false,
      },
      columns: [
        {
          field: "name",
          header: "Nombre",
          sort: true,
          center: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/horario/licencias/listado"),
        },
        // {
        //   name: "Eliminar",
        //   action: onDelete,
        //   hidden: router
        //     .getRoutes()
        //     .find((route) => route.path === "/horario/licencias/listado"),
        // },
      ],
    });

    const validate = () => {
      let valid = true;

      if (!license.data.name) {
        license.rules.name = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        buildingId: store.state.general.selectedBuilding,
        name: license.data.name,
      };

      const response = await store.dispatch(
        actions.personnelActions.createLicenseType,
        request
      );

      if (response.ok) {
        store.commit("setSelectedLicenseTypeList", []);
        license.data.name = "";
        license.showDialog = false;
        await store.dispatch(
          actions.personnelActions.findAllLicenseType,
          store.state.general.selectedBuilding
        );
        store.commit("setLoading", false);
      } else {
        store.commit("setLoading", false);
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    watchEffect(() => {
      if (license.data.name) license.rules.name = false;
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedLicenseTypeList", []);
      store.commit("addBreadcrumb", {
        label: "Tipos de Licencia",
        to: route.path,
      });
      await store.dispatch(
        actions.personnelActions.findAllLicenseType,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    return { store, license, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
